import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import ApplicationFeeInfoModal from './elements/ApplicationFeeInfoModal'
import ApplicationFeeDetailsModal from './elements/ApplicationFeeDetailsModal'

import { Button } from 'mmfintech-portal-commons'
import { SuccessSubtitle, SuceessContainer } from './styled/sumSubBanner.styled'

import { extractCurrencyCode, GlobalContext, isValidObject, isValidString, OtpContext, tr } from 'mmfintech-commons'
import { actions, paths, useOnboardingBanner } from 'mmfintech-backend-api'

import { MerchantAccountTypeEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

import SuccessImage from '../../images/icons/success.svg'

const errorTimeout = 1000 * 3 // 3 seconds

const useOnboarding = () => {
  const { modalHide, modalShow } = useContext(GlobalContext)
  const { setOtpOnSuccess } = useContext(OtpContext)

  const { merchant, paymentAccounts, applicationFeeInitiateError } = useSelector(
    ({ user: { merchant }, banking: { paymentAccounts }, otp: { applicationFeeInitiateError } }) => ({
      merchant,
      paymentAccounts,
      applicationFeeInitiateError
    }),
    shallowEqual
  )

  const [showError, setShowError] = useState(true)

  const history = useHistory()
  const dispatch = useDispatch()

  const { accountSetupFee, capabilities, entityType, accountType, onboardingStatus } = merchant || {}
  const { ibanEligible } = capabilities || {}

  const startSumSubOnboarding = () => {
    if (isValidString(entityType)) {
      if (accountType === MerchantAccountTypeEnum.PROSPECT && onboardingStatus === OnboardingStatusEnum.NOT_STARTED) {
        history.push(paths.onboarding.selectType())
      }
    } else {
      history.push(paths.onboarding.selectType())
    }
  }

  const { questionnaire, startOrContinueOnboarding } = useOnboardingBanner({
    startOnboarding: startSumSubOnboarding
  })

  const SuccessMessage = () => {
    const handleBack = () => {
      modalHide()
    }

    return (
      <SuceessContainer className='centered' data-test='application-fee-paid-success'>
        <img src={SuccessImage} alt='' />
        <SuccessSubtitle className='title'>
          {tr('FRONTEND.PAYMENT.SUCCESS', 'Your Application Fee has been paid successfully')}
        </SuccessSubtitle>
        <Button
          type='button'
          color='primary'
          text={tr('FRONTEND.EXCHANGE.BUTTON_OK', 'Ok')}
          onClick={handleBack}
          data-test='button-ok'
        />
      </SuceessContainer>
    )
  }

  const payAccountSetupFee = () => {
    modalHide()
    dispatch(actions.banking.depositCleanup())
    setShowError(true)

    const euroAccount = paymentAccounts.find(account => extractCurrencyCode(account) === 'EUR')
    const { availableBalance } = euroAccount || {}
    const { amount, currency } = accountSetupFee || {}

    if (availableBalance >= amount) {
      const handleSubmit = () => {
        setOtpOnSuccess(() => () => {
          dispatch(actions.merchant.fetch())
          modalShow({
            options: {
              size: 'auto',
              transparent: true,
              closeOnClickOutside: false,
              closeOnEscape: false
            },
            content: <SuccessMessage />
          })
        })
        dispatch(actions.banking.applicationFeeInitiate())
      }
      modalShow({
        options: {
          size: 'auto',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: (
          <ApplicationFeeInfoModal amount={amount} currency={currency} onClose={modalHide} onSubmit={handleSubmit} />
        )
      })
    } else {
      modalShow({
        options: {
          size: 'auto',
          transparent: true,
          closeOnClickOutside: true,
          closeOnEscape: true,
          onClose: () => dispatch(actions.banking.depositCleanup())
        },
        content: <ApplicationFeeDetailsModal amount={amount} currency={currency} />
      })
    }
  }

  const shouldPayAccountSetupFee = () => isValidObject(accountSetupFee)

  const shouldRequestIban = () => ibanEligible
  const requestIban = () => history.push(paths.ibanIssuing())

  useEffect(() => {
    if (applicationFeeInitiateError) {
      setTimeout(() => {
        setShowError(false)
      }, errorTimeout)
    }
  }, [applicationFeeInitiateError])

  return {
    showError,
    requestIban,
    questionnaire,
    shouldRequestIban,
    payAccountSetupFee,
    shouldPayAccountSetupFee,
    startOrContinueOnboarding
  }
}

export default useOnboarding
